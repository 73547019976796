<template>
	
	<div class="cockpitview">

		<b-modal v-model="orgchangesconfirm" centered size="sm-p" no-close-on-backdrop no-close-on-esc>
    <template v-slot:modal-header>
      <h3 class="modal-title board_headeractivation">Changes not saved</h3>
      <a class = "close-modal dialog-close-button">
        <i class="fal fa-times fa-lg " @click="orgchangesconfirm=false"></i>
      </a>
    </template>

    <p>The changes you have made have not yet been saved and will be lost if you continue. Are you sure you want to continue?</p>

    <template v-slot:modal-footer="{ ok,cancel }">
      <b-button v-if="!isworking" @click="orgchangesconfirm = false">Cancel</b-button>      
      <b-button v-if="!isworking" class="btn-primary" @click="navigateway()">Proceed without saving</b-button> 

      <b-button v-if="isworking" class="disabled" >Cancel</b-button>
      <b-button v-if="isworking" class="btn-primary disabled">Proceed without saving <i class="fa fa-circle-o-notch fa-spin"></i></b-button>
    </template>
  </b-modal>


  <b-modal v-model="makeorginactivemodalshow" centered size="sm-p" no-close-on-backdrop no-close-on-esc>
    <template v-slot:modal-header>
      <h3 class="modal-title board_headeractivation">Make organization inactive</h3>
      <a class = "close-modal dialog-close-button">
        <i class="fal fa-times fa-lg " @click="makeorginactivemodalshow=false"></i>
      </a>
    </template>

    <p>Warning! You are about to make this organization inactive. This means that users belonging to the organization will no longer be able to access it - including all projects and objectives that belong to the organization.</p>
    <p>You or another organization administrator can make the organization active again - no data will be deleted.</p>
    <p>Are you sure you want to make this project inactive?</p>

    <template v-slot:modal-footer="{ ok,cancel }">
      <b-button v-if="!isworking" @click="makeorginactivemodalshow = false">Cancel</b-button>      
      <b-button v-if="!isworking" class="btn-primary" @click="disableorg()">Make inactive</b-button> 

      <b-button v-if="isworking" class="disabled" >Cancel</b-button>
      <b-button v-if="isworking" class="btn-primary disabled">Make inactive <i class="fa fa-circle-o-notch fa-spin"></i></b-button>
    </template>
  </b-modal>



  <b-modal v-model="makeorgactivemodalshow" centered size="sm-p" no-close-on-backdrop no-close-on-esc>
    <template v-slot:modal-header>
      <h3 class="modal-title board_headeractivation">Make organization active</h3>
      <a class = "close-modal dialog-close-button">
        <i class="fal fa-times fa-lg " @click="makeorgactivemodalshow=false"></i>
      </a>
    </template>

    <p>When you make an organization active, all its projects and objectives will immediately become available to all organization members with the appropriate permissions.</p>
    <p>Are you sure you want to make this organization active?</p>

    <template v-slot:modal-footer="{ ok,cancel }">
      <b-button v-if="!isworking" @click="makeorgactivemodalshow = false">Cancel</b-button>      
      <b-button v-if="!isworking" class="btn-primary" @click="enableorg()">Make active</b-button> 

      <b-button v-if="isworking" class="disabled" >Cancel</b-button>
      <b-button v-if="isworking" class="btn-primary disabled">Make active <i class="fa fa-circle-o-notch fa-spin"></i></b-button>
    </template>
  </b-modal>


		<div v-if="organization.name && myrole && myrole.toUpperCase() != 'ADMINISTRATOR' && organization.status != 'disabled'">
			
			<div class="float-left w-100">
				<div class="company-logo d-flex float-left" :class ="{' B500-b':!organization.logo, 'NO-b': organization.logo }">          
					<img style="border-radius:4px;" width="40" height="40" :src = "organization.logo" v-if="organization.logo" @error="imageonerror($event)">
					<img style="border-radius:4px;" width="40" height="40" :src = "'/static/img/names/' + organization.name.charAt(0).toLowerCase()+'.png'" @error="imageonerror($event)" v-else>
	            </div>

	            <div class="float-left" style="height: 40px; padding-left: 12px;"><h3 class="mb-0" style="margin-top: 6px;">{{ organization.name }}</h3></div>
        	</div>

        	<div class="mt-2 float-left w-100">Description</div>
        	<p v-if="!organization.description" class="float-left">- the organization owner has not yet added a description -</p>

			<div v-if="organization.description" class="float-left pt-2 w-100 noshadow noborder readonly descriptiontext mb-0 fr-view" v-html="$options.filters.bindevents(organization.description)">
			</div>
		</div>


		<div v-if="1==2">
			<button type="button" @click="generategettingstarted()" v-if="generating==false">Generate a getting started project</button>
			<button type="button" disabled v-if="generating==true">Generate a getting started project</button>
		</div>


		<div v-if="organization.status == 'disabled'">

			<div class ="d-flex flex-row" data-display="static" id ="sidebardropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="company-logo d-flex" :class ="{' B500-b':!organization.logo, 'NO-b': organization.logo }" >
                  
                  <img style="border-radius:4px;" width="40" height="40" :src = "organization.logo" v-if="organization.logo" @error="imageonerror($event)">
                  <img style="border-radius:4px;" width="40" height="40" :src = "'/static/img/names/' + organization.name.charAt(0).toLowerCase()+'.png'" @error="imageonerror($event)" v-else>
              

                </div>

                  <div class="side-icon-text mr-2 my-auto overflow-string w-100 hide-text" >
                    <span class ="ml-3 orgname"> {{organization.name || 'Flowe'}} </span>

                    <span class="ml-1 badge badge-light">Inactive</span>
                  </div>


              </div>


           <div class="mt-3" v-html="$options.filters.bindevents(organization.description)"></div>



		</div>
		<div v-if="organization && organization.status != 'disabled' && myrole && myrole.toUpperCase() == 'ADMINISTRATOR' ">
		<div>
			<label> Organization icon </label>
			<div class ="d-flex flex-row ml-2">
				<div  class="smr-3 text-center d-flex align-items-center" >
					<imagewithupload :myrole="myrole"  :organization="organization"  :imageurl="organizationlogo" @onimageuploadsuccess="imageuploadsuccess" @onimageuploadfailed="imageuploadfailed" @onimageisloading="imageisloading" @onupload="uploaderror=''"></imagewithupload>

					 

				</div>
				<base-icon v-if="myrole && myrole.toUpperCase() == 'ADMINISTRATOR'" iconSize ="sm" forcemd class ="clickable info nav-icon" icon @click.native.prevent="showinstruction=!showinstruction">
					<template slot = "icon">
						<span> 
							<i class="fa-info-circle " :class = "{'fal':!showinstruction,'fas':showinstruction}" ></i>
						</span>
					</template>
				</base-icon>

				 

			</div>

			<div v-if="uploaderror && uploaderror != ''">
				<span class="text-danger uploaderror" v-if="uploaderror=='invalid'">File is not a valid image</span> 
          <span class="text-danger uploaderror" v-if="uploaderror=='toolarge'">Image must be below 10mb</span>
          <span class="text-danger uploaderror" v-if="uploaderror=='uploadfailed'">File upload failed</span>  
			</div>
			<div class="B100-b w-100 p-2 mt-2 instruction" v-if="showinstruction">
				
				<span>The organization icon is used to identify your organization in Flowe, and is shown at the top of the left navigation bar on tablet and desktop devices.</span><br>
				Some tips on choosing a good icon:
				<ul>

					<li> Use a square image that is at least 132px and has a solid background color.</li>
					<li> Use a graphical logo or image rather than text.</li>

				</ul>
				<a class ="actionprimarydark" @click="revertimagetodefault()">Click here </a> to revert back to the default icon.

			</div>
		</div>


		<advancedinput inputName = "Organization name" v-if="myrole" class ="mt-2" label = "Organization name" :myrole ="myrole" :editorstate ="editorstate" :textvalue ="organization.name" @no ="canceltitle()" @yes = "onsavename()" @click = "opentitle()"/>


		<div class="form-group">
				<label>Description</label>
				<div class="editable" v-if="( editorstate.description.state == 'idle') " @click.prevent="opendescription()">
					
					<div class="noshadow readonly descriptiontext mb-0 fr-view" :class ="{'card p-2': myrole.toUpperCase() == 'ADMINISTRATOR'}" v-html="organization.description">
					</div>
					<i v-if ="myrole.toUpperCase() == 'ADMINISTRATOR'" class="fa fa-pencil editableicon description"></i>
				</div>

				
				<div v-if="myrole && myrole.toUpperCase() == 'ADMINISTRATOR' && froalaisready" :class = "{'hide':editorstate.description.state == 'idle'}">
					

						<froala id="cockpitdescription" :config="froalaconfig" v-model="editorstate.description.content" class="mb-0" :onManualControllerReady="initializefroala"></froala>


				</div> 
				<div class="mt-2 text-right" v-if="editorstate.description.state=='editing'">
				<button-gruppe text1 = "Save" @no ="canceldescription()" @yes = "onsavedescription()" default = "none" :disabled="imageuploading == true"></button-gruppe>
			</div>

			</div>

		</div>

		<hr v-if="myrole && myrole.toUpperCase() == 'ADMINISTRATOR'">
		<div class="form-group" v-if="myrole && myrole.toUpperCase() == 'ADMINISTRATOR'">
			<button v-if="organization.status != 'disabled'" class="btn btn-warning-secondary btnmake" @click.prevent="makeorginactivemodalshow=true">Make organization inactive</button>

			<button v-if="organization.status == 'disabled'" class="btn btn-warning-secondary btnmake" @click.prevent="makeorgactivemodalshow=true">Make organization active</button>
		</div>

	</div>
</template>

<style lang = "scss" scoped>


textarea{
	display: none !important;
}
</style>
<script type="text/javascript"></script>

<script>
	import {bus} from '@/main'
	import advancedinput from '@/components/advanced/input-with-pencil'
	import imagewithupload from '@/views/organizations/components/imagewithupload'

	import Signinapi from '@/services/api/signin'


	

	export default{
		name: "about",
		data(){
			return{
				cancelevent:false,
				generating:false,
				showinstruction: false,
				editorstate: { name:{}, description:{} }, 
				froalaconfig: {},
				errors:{},
				imageuploading:false,
				makeorginactivemodalshow : false,
				makeorgactivemodalshow : false,
				isworking : false,
				froalaisready : false,
				orgchangesconfirm:false,
				editorchanged : false,
				lastnext : null,
				uploaderror : ""
			}
		},
		created(){

			let self = this

			$.getJSON( "https://s3token2.flowe.io/", function(data){

				//console.log( self.froalaconfig.imageUploadToS3, data )
				//data.keyStart = "uploads"
			
				self.froalaconfig = self.$store.state.config
				self.froalaconfig.imageUploadToS3 = data  
				//self.froalaconfig.imageUploadToS3 = data	
				self.froalaconfig.fileUploadToS3 = data 
				self.froalaconfig.heightMax = 'none'


				self.froalaconfig.placeholderText = 'Provide an optional description of the organization.'

				self.froalaconfig.events = {
					'blur':function(){

						if( self.onblursavedescription ){
						//	self.onblursavedescription()
						}
					},
					'image.beforeUpload': function (images, lo ) {
						self.imageuploading = true        
					},
					'image.inserted': function ($img, response) {
						self.imageuploading = false
					},
					'contentChanged': function () {
			      // Do something here.
			      // this is the editor instance.


			      if( self.editorstate.description.state=='editing' ){
			      	 self.editorchanged = true
			      }
			     
			    }
				}

				self.froalaisready = true
			})


			this.editorstate.name = { content : this.organization.name, state: 'idle' }
			this.editorstate.description = { content : this.organization.description, state: 'idle' } 

		},
		mounted(){

			//console.log(this.froalaconfig)

			this.editorstate.name = { content : this.organization.name, state: 'idle' }
			this.editorstate.description = { content : this.organization.description, state: 'idle' } 


			document.title ='Organizations | Flowe'
			
			
			if( this.organization._id ){
				this.editorstate = {mode: 'idle', name:{content: this.organization.name ,state: 'idle'}, description: {content: this.organization.description , state: 'idle'}}
			}else{
				this.editorstate = {mode: 'idle', name:{content:'',state: 'idle'}, description: {content:'', state: 'idle'}}
			}

			


			

			

			

			//this.$store.dispatch('fechtOrganization', {token: this.$store.getters.token, key: this.orgkey }).then((response) => {

				//console.log( response.data , "zzzzcccc")
			//	self.organization = response.data.organization

			//})
		},

		 watch:{

		 	organization : function( e, ev ){

		 	//console.log("zzzzzzzzzz")
		 		this.editorstate.name.state='idle';
		 		this.editorstate.description.state='idle';
		 		this.editorstate.name.content = ""
		 		this.editorstate.description.content = ""
		 		

		 		if( e.name ){
		 			this.editorstate.name.content = e.name
		 		}
		 		if( e.description ){
		 			this.editorstate.description.content = e.description
		 		}
		 		
		 	}
		 },

		methods: {

			onsavedescription(){

				this.cancelevent = true
				this.savedescription()

			},

			onblursavedescription(){

				let self = this

				setTimeout( function(){

					if( self.cancelevent == false ){
						self.savedescription()
					}

				}, 200)	
			},

			onsavename(){

				this.cancelevent = true
				this.savename()
			},

			onblursavename(){

				let self = this

				setTimeout( function(){

					if( self.cancelevent == false ){
						self.savename()
					}

				}, 200)	


			},

			generategettingstarted(){

				this.generating = true

				let self = this

				Signinapi.Generategettingstarted( this.loggedinuser._id, this.organization._id ).then( function( data ){

					self.generating = false
					//if( data.ok ){
					//	self.$router.push("/")
					//	location.href="/"
					//}

					self.$router.push("/"+self.orgkey+"/projects")

				})

				/*axios.get( "members/me/boards" ).then( function( data ){

           console.log( data, "boards")
        })

        Signinapi*/

			},

			navigateway(){
				self.editorchanged = false

				if( this.lastnext ){

					this.lastnext()
				}
			},

			gotonext(next) {
				// body...
			},


			imageonerror( el ){
	      		el.currentTarget.src = '/static/img/names/org.png'
	    	},


			enableorg(){

				this.isworking = true

				let self = this

				this.organizationupdate('status', 'active', function(){
	            	
	            	//alert('ccc')
	            	//setTimeout( 1000, function(){
					//	alert('aa')

					bus.$emit('craeteaToast', {content:"The " + self.organization.name + " organization was successfully activated, and you are now signed in to it.",title:"Changed organization", variant:'info',append:true, sup:''})
						
						self.makeorginactivemodalshow = false
	            		self.makeorgactivemodalshow = false


	            		self.isworking = false
					//})
	            })
			},

			disableorg(){

				this.isworking = true

				let self = this

				this.organizationupdate('status', 'disabled', function(){
					
					//alert('ccc')
					//setTimeout( 3000, function(){
					//	alert('aa')
					//	$('body').click()

					bus.$emit('craeteaToast', {content:"The organization was made inactive, and you are instead now signed in to the " + self.organization.name + " organization.",title:"Changed organization", variant:'info',append:true, sup:''})


						self.makeorginactivemodalshow = false
						self.makeorgactivemodalshow = false

						self.isworking = false
					//})
				})

			},
			revertimagetodefault(){
				var newlogo = ""
				//this.organization.logo = newlogo
				this.organizationupdate('logo', newlogo)
			},
			imageisloading(){
				//this.organization.logo = ""
			},
			
			imageuploadsuccess( newlogo ){

				this.organizationupdate('logo', newlogo)

			},
			imageuploadfailed( uploaderror ){
				this.uploaderror = uploaderror
			},
			canceltitle(){

				this.cancelevent = true

				this.editorstate.name.state='idle'; 
				this.editorstate.name.content = this.organization.name
			},

			opentitle(){

				this.cancelevent = false

				if(this.editorstate.description.state == 'idle'){
					this.editorstate.name.state = 'editing'

					this.$nextTick(function() {
						setTimeout(()=>{
							$('#cockpittitle').focus()
						},100)
					})
				}
			},

			opendescription(){

				this.cancelevent = false

				let self = this

				if(this.editorstate.name.state == 'idle' && this.myrole.toUpperCase() == 'ADMINISTRATOR'){
					this.editorstate.description.state='editing'
					this.$nextTick(function() {
						self.editorchanged = false
					})
				}
			},

			canceldescription(){

				this.cancelevent = true

				this.editorstate.description.state='idle'
				this.editorstate.description.content = this.organization.description
			},

			


			savename(){

				this.cancelevent = false

				/*this.$store.dispatch('updateOrganization', {field:field, newvalue:newvalue, context:this.organization.key ,t:this.$store.getters.token})*/
				//this.organization.name = this.editorstate.name.content
				this.organizationupdate('name', this.editorstate.name.content)
				this.editorstate.name.state='idle'
			},

			savedescription(){

				let self = this

				this.editorstate.description.content = this.linkInitControls.getEditor().html.get()
				//this.organization.description = this.editorstate.description.content 
				this.organizationupdate('description', this.editorstate.description.content ); 
				this.editorstate.description.state='idle'

				this.editorchanged = false
			},


			organizationupdate(field, value, callback){
				var newvalue = value

				//callback()

				this.$store.dispatch('updateOrganization', {field:field, newvalue:newvalue, context:this.organization.key ,t:this.$store.getters.token}).then((response) =>{

					

				}).finally( function(){

					//self.editorchanged = false

					setTimeout( function(){ $('.btnmake').blur() } , 300)

					if( callback ){
						callback()
					}
				})

			},
			initializefroala: function(initControls) {
				this.linkInitControls = initControls;
				this.linkInitControls.initialize()
				this.editorchanged = false
				this.placeholderText = 'Start typing something...'
			},
		},
		computed: {

			orgkey(){
		      return this.$route.params.orgname
		    },

			myrole(){

				var toreturn = "Member"

				if( this.organization )  {
					toreturn = this.organization.myrole ? this.organization.myrole : "Member"
				}

				return toreturn
			},

			loggedinuser(){
				return this.$store.getters.user
			},

			organization(){
				return this.$store.state.organizations.selectedorganizations
			},
			organizationlogo(){
				return this.organization.logo
			},

			userorgitems(){
		      return this.$store.state.organizations.listorganizations
		    },
		},
		beforeRouteLeave (to, from , next) {

			if( this.editorchanged == true && this.editorstate.description.state=='editing' ){
				this.orgchangesconfirm = true
				this.lastnext = next
			}else{
				next()
			}
		
			

			//this.gotonext(next)
		 // const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
		  //if (answer) {
		   // next()
		 // } else {
		  //  next(false)
		  //}
		},
		components: {
			advancedinput, imagewithupload
		}		
	}
</script>